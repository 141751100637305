<template>
  <div id="attendances" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="schoolClasses" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          schoolClasses.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="School Class" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row.id)"
            :show-update="false"
            :show-remove="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  name: 'attendances',
  data() {
    return {
      user: {},
      schoolId: null,
      pageTitle: 'Attendance',
      schoolClasses: [],
    }
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    this.setParams()
  },
  methods: {
    initializeInfo(schoolClassId) {
      this.$router.push(
        `/school/${this.schoolId}/attendance_info/${schoolClassId}`
      )
    },
    setParams() {
      fetchUser().then((user) => {
        this.user = user
        this.schoolId = user.school_id
        this.fetchSchoolClasses()
        this.$store.commit('setSubMenus', [])
      })
    },
  },
}
</script>
